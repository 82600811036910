<template>

  <div class="connent_title">
    <div class="connent_header">
      <span>权限设置</span>
    </div>
  </div>
  <el-form class="demo-ruleForm">
    <div style="margin-bottom: 20px">
      <span>功能列表</span>
      <span style="float: right;">数据权限（自己/全部）</span>
    </div>
    <el-tree
        ref="tree"
        :data="data"
        show-checkbox
        node-key="id"
        :expand-on-click-node="false"
        :render-content="renderContent"
        :default-expanded-keys="expanded"
        :default-checked-keys="checked">
    </el-tree>
    <br>
    <el-button type="primary" @click="submitForm()">保存</el-button>
  </el-form>

</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'

export default {
  data() {
    return {
      data: [],
      checked: [],
      expanded: [],
    }
  },
  created() {
    var that = this

    axios.get('/Authority/List?size=1000').then((auth) => {
      auth = auth["data"]

      axios.get('/RoleAuthority/List?size=1000&role_id='+this.$route.query.id).then((data) => {

        var reset_data = {}
        var role_access_data = {}
        var role_date_data = {}

        var roleauth = data["data"]["list"]

        // 归类角色权限
        roleauth.forEach(function (o){
          role_access_data[o.tag] = 1
          role_date_data[o.tag] = o.data_level || 1
        })

        // 归类接口信息
        auth.list.forEach(function (o){
          if (o.hide != true){
            if (!reset_data[o.model_name]){
              reset_data[o.model_name] = {
                label: o.model_name
                ,id: o.id
                ,children: {}
              }
            }
          }
        })

        auth.list.forEach(function (o){
          if (o.hide != true){
            if (!reset_data[o.model_name].children[o.table_name]){
              reset_data[o.model_name].children[o.table_name] = {
                label: o.table_name
                ,id: o.id
                ,spread: true
                ,children: []
              }
            }

            o.data_level = role_date_data[o.tag]
            reset_data[o.model_name].children[o.table_name].children.push({
              label: o.describes == "列表" ? o.describes + "（菜单）" : o.describes
              ,id: o.id
              ,spread: true
              ,data : o
            })
            if (role_access_data[o.tag] == 1){
              that.expanded.push(o.id)
              that.checked.push(o.id)
            }
          }
        })

        for(let key in reset_data){
          var children1 = []
          for(let key2 in reset_data[key].children){
            children1.push(reset_data[key].children[key2])
          }
          reset_data[key].children = children1
        }

        var datares = []

        for(let key in reset_data){
          this.expanded.push(reset_data[key].id)
          datares.push(reset_data[key])
        }

        this.data = datares

      })
    })

  },
  methods: {
    submitForm() {
      var that = this
      var postData = []
      this.$refs.tree.getCheckedNodes().forEach(item=>{
        if (item.data){
          postData.push({tag: item.data.tag, access_level: 1, data_level: item.data.data_level || 1, role_id: this.$route.query.id})
          return
        }
      })

      axios.post('/RoleAuthority/Modify?id='+this.$route.query.id, postData,{headers: {'Content-Type': 'application/json'}}).then(() => {
        ElMessage.success('修改成功')
        that.$emit('onClose')
        that.$emit('getTableData')
      })
    },
    renderContent(h, { node, data, store }) {

      if (!data.data || (data.data && (data.data.model_name == "用户管理" || data.data.model_name == "系统管理" || data.data.describes == "增加"))){
        return h(
            'span',
            {
              class: 'custom-tree-node',
            },
            h('span', null, node.label),
        )
      }

      return h(
          'span',
          {
            class: 'custom-tree-node',
          },
          h('span', null, node.label),
          h(
              'div',
              null,
              h(
                  'input',
                  {
                    "type":"radio",
                    "name":data.data.tag,
                    "value":"2",
                    "class":"cradio",
                    "checked":data.data.data_level == 2 ? true:false,
                    onClick: () => data.data.data_level = 2,
                  },
                  ''
              ),
              h(
                  'input',
                  {
                    "type":"radio",
                    "name":data.data.tag,
                    "value":"1",
                    "class":"cradio",
                    "checked":data.data.data_level == 1 || !data.data.data_level ? true:false,
                    onClick: () => data.data.data_level = 1,
                  },
                  ''
              )
          )
      )
    },
  }
}
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.cradio {
  height: 17px;
  width: 17px;
  margin-right: 15px;
}
</style>